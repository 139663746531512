.nav-tabs {
    display: none;
  }
  
  @media (min-width: 768px) {
    .nav-tabs {
      display: flex;
      flex-flow: column nowrap;
    }
    .nav-tabs {
      border-bottom: none;
      border-right: 1px solid #ddd;
      display: flex;
    }
    .nav-tabs {
      margin: 0 15px;
    }
    .nav-tabs .nav-item + .nav-item {
      margin-top: 0.25rem;
    }
    .nav-tabs .nav-link {
      transition: border-color 0.125s ease-in;
      white-space: nowrap;
    }
    .nav-tabs .nav-link:hover {
      background-color: #f7f7f7;
      border-color: transparent;
    }
    .nav-tabs .nav-link.active {
      border-bottom-color: #ddd;
      border-right-color: #fff;
      border-bottom-left-radius: 0.25rem;
      border-top-right-radius: 0;
      margin-right: -1px;
    }
    .nav-tabs .nav-link.active:hover {
      background-color: #fff;
      border-color: #0275d8 #fff #0275d8 #0275d8;
    }
  
    .card {
      border: none;
    }
  
    .card .card-header {
      display: none;
    }
  
    .card .collapse {
      display: block;
    }
  }
  
  @media (max-width: 767px) {
    .tab-pane {
      display: block !important;
      opacity: 1;
    }
  }
  