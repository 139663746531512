.maincontent.aboutuser {
    max-width: 1300px;
    margin: auto;
    gap: 40px;
    display: flex;
    padding: 24px 20px 40px;
}
.backbutton button {
    width: 40px;
    height: 40px;
    cursor: pointer;
    position: relative;
    border-radius: 36px;
    font-size: 0px;
    display: block;
    border: 0px;
  
    box-shadow: 0px 0px 4px rgb(0 114 187 / 25%);
}
.aboutuser .aboutsection {
    flex: 1;
}
.aboutuser .maincontent.guestprofileview {
    padding-bottom: 0px;
}
.aboutcontainer{
    max-width: 1100px;
    margin: auto;
    background: #FBFBFB;
    border-radius: 10px;
}
.aboutcontainer .viewrow {
    padding: 24px;
    font-size: 16px;
    line-height: 19px;
    text-align: justify;
    letter-spacing: 0.02em;
    color: #2B2B39;
    position: relative;
}
.aboutcontainer .viewrow::after {
    width: 93%;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    right: 0px;
    margin: auto;
    background: #EEEEEE;
}
.aboutcontainer .viewrow:last-child::after {
    display: none;
}
.aboutcontainer .viewrow h1.blocktitle {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    color: #11072F;
    padding-bottom: 6px;
    margin-bottom: 16px;
    position: relative;
}
.aboutcontainer .viewrow h1.blocktitle::before {
    width: 60px;
    height: 1px;
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0px;
    background: #11072F;
}
.aboutcontainer .viewrow.description .value {
    line-height: 28px;
}
.aboutcontainer .viewrow .value ul,
.aboutcontainer .viewrow .value ul li {
    list-style-image: none;
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.aboutcontainer .viewrow .value ul li {
    display: inline-block;
    vertical-align: middle;
    border-right: 2px solid #2B2B39;
    line-height: 19px;
    padding-right: 8px;
    margin-right: 8px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.aboutcontainer .viewrow.addresslocation .value ul {
    display: inline-block;
    vertical-align: middle;
}
.aboutcontainer .viewrow.addresslocation .value ul::before {
    content: ",";
    position: relative;
    margin-right: 8px;
}
.aboutcontainer .viewrow.addresslocation .value ul:first-child::before {
    margin-right: 0px;
    display: none;
}

.aboutcontainer .viewrow.addresslocation .value ul li {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
}
.aboutcontainer .viewrow.addresslocation .value ul li:nth-child(2)::before {
    content: ",";
    position: relative;
    margin-right: 8px;
}

.aboutcontainer .viewrow.addresslocation .value ul li:last-child {
    border-left: 2px solid #2B2B39;
    padding-left: 8px;
    margin-left: 8px;
}

.aboutcontainer .viewrow .value ul li:last-child {
    border-right: 0px;
}

@media only screen and (min-width: 0px) and (max-width: 767px) {
    .maincontent.aboutuser {
        gap: 0px;
        flex-wrap: wrap;
    }
    .backbutton {
        flex: 1 100%;
        text-align: center;
    }
    .backbutton button {
        width: 30px;
        height: 30px;
        margin: auto auto 15px;
    }
    .aboutuser .aboutsection {
        flex: 1 100%;
    }
    .aboutcontainer .viewrow {
        padding: 20px;
        font-size: 15px;
        line-height: 19px;
        text-align: left;
    }
    .aboutcontainer .viewrow h1.blocktitle {
        font-size: 17px;
    }
    .aboutcontainer .viewrow.description .value {
        line-height: 26px;
    }
}
@media only screen and (min-width: 768px) and (max-width: 1280px) {
    .maincontent.aboutuser {
        gap: 20px;
    }
    .aboutcontainer .viewrow h1.blocktitle {
        font-size: 18px;
    }
    .aboutcontainer .viewrow {
        font-size: 15px;
    }
}
.guestprofileinformation {
    max-width: 1200px;
    margin: auto auto 16px;
}

.guestprofileinformation .coverimage {
    height: 420px;
    border-radius: 2px;
    background-color: #dcdcdc;
}

.guestprofileinformation .coverimage img {
    height: 420px;
    border-radius: 2px;
    background-color: #dcdcdc;
    object-fit: cover;
    width: 100%;
}
.guestprofiledetails {
    text-align: center;
}

.guestprofiledetails .userpict {
    width: 180px;
    height: 180px;
    border: 10px solid #fff;
    margin: -160px auto auto;
    border-radius: 60%;
    background: #dcdcdc;
    z-index: 0;
    position: relative;
}

.guestprofiledetails .userpict .img {
    width: 160px;
    height: 160px;
    border-radius: 60%;
}

.guestprofiledetails .username h2{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 37px;
    color: #11072F;
    margin: 10px 0px 18px;
}
.guestprofiledetails .description{
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 30px;
}