@import '~antd/dist/antd.css';

body {
  padding: 0px;
  margin: 0px;
  font-size: 14px;
  line-height: 26px;
  font-family: 'Roboto', sans-serif;
  background: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
