.Page {
}

.MainLayout {
}

.HeaderImage {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  background-image: url('../../assets/header.jpg');
  background-size: 100%;
  background-position-y: 50%;
  height: 222px;
  width: 100%;
}
