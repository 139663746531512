.Post {
  display: flex;
  padding: 20px;
}

.Minimal {
  border: 2px solid #fafafa;
  border-radius: 5px;
}

.Post + .Post {
  border-top: 2px solid #fafafa;
}

.User {
  width: 25%;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  border-right: 2px solid #fafafa;
}

.MinimalUser {
  width: 20%;
  max-width: 150px;
}

.Username {
  font-size: 20px;
  align-self: center;
}

.Usergroup {
  align-self: center;
}

.Avatar {
  width: 125px;
  align-self: center;
  margin-top: 10px;
}

.MinimalAvatar {
  width: 75px;
}

.Avatar img {
  max-width: 100%;
  border-radius: 100%;
}

.Message {
  margin-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
}

.Date {
  justify-self: flex-end;
  color: #bfbfbf;
  text-align: right;
}

.Stats {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: 20px;
  padding: 0 10px;
}
