header {
  background-color: #0072BB;
}
.headerarea {
  max-width: 1440px;
  margin: auto;
  display: flex;
  align-items: center;
  padding: 6px 24px;
}
.LogoContainer {
  display: flex;
  align-items: flex-start;
}
.logoarea img {
  height: 80px;
}
.headerarea .menuarea {
  flex: 1;
  text-align: center;
}
header .headerarea ul,
header .headerarea ul li {
    padding: 0px;
    margin: 0px;
    list-style: none;
    list-style-image: none;
}

header .headerarea ul.menu {
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    color: #ffffff;
}

header .headerarea .menuarea ul.menu li a {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 18px;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0px 28px;
    cursor: pointer;
}

header .headerarea .signuparea ul.menu li a {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 18px;
    color: #02CCFE;
    background: #fff;
    padding: 10px 32px;
    border-radius: 4px;
    margin-left: 24px;
    text-decoration: none;
    transition: all 0.5s ease;
}

header .header-area .signuparea ul.menu li.sign-up a {
    margin-left: 0px;
}

header .header-area .signuparea ul.menu li a:hover {
    background: #02CCFE;
    color: #fff;
}




























/* .Logo {
  color: #ffffff;
  font-weight: bold;
  cursor: pointer;
  font-size: 2em;
  line-height: normal;
}

.Logo span{
  display: block;
}

.HeaderMenu {
  background: transparent;
  border: none;
  color: #ffffff;
}
.HeaderMenu li a{
  color: white!important;
}

.HeaderMenuRight{
  background: transparent;
  border: none;
  color: #ffffff;
}

.HeaderMenuRight li a{
  color: white!important;
}
 */
