.l-status {

}
.l-status .green,
.l-status .red{
    padding: 4px;
    border-radius: 4px;
    font-weight: bold;
    color: #fff;
}
.l-status .green{
    background: green;
}
.l-status .red{
    background: red;
}

.interested-main-wrapper {
    max-width: 1100px;
    margin: auto;
    border-radius: 4px;
    text-align: center;
    text-transform: capitalize;
}

.main-wrapper-head {
    display: flex;
    font-weight: bold;
    background: #0072bb;
    color: #fff;
}

.main-wrapper-head div {
    flex: 1;
    border: 1px solid #000;
    padding: 10px;
    font-size: 16px;
}

.interested-item {
    display: flex;
}

.interested-item div {
    flex: 1 1;
    border-bottom: 1px solid #000;
    border-right: 1px solid #000;
    padding: 10px;
}
.interested-item .transaction {
    border-left: 1px solid #000;
}
.main-wrapper-bottom {
    display: flex;
    border: 1px solid #000;
    background: #0072bb;
    color: #fff;
}

.main-wrapper-bottom div {
    flex: 1;
    padding: 10px;
}
.main-wrapper-bottom .loanamount,
.main-wrapper-bottom .commission-value{
    border-right: 1px solid #000;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    font-weight: bold;
    font-size: 18px;
}
.main-wrapper-bottom .transactiondate, .main-wrapper-bottom .commission{
    font-weight: bold;
    font-size: 18px;
    text-align: right;
}