.video-input-box {
  border: 1px solid #ccc;
  padding: 20px;
  margin: 20px 0;
  border-radius: 4px;
}
.video-input-box .item-row {
  border: 1px solid #000;
  padding: 20px;
  border-radius: 4px;
  position: relative;
}
.video-input-box .item-row .remove-btn{
  position: absolute;
    right: 20px;
    top: 7px;
}
.input-box {
  display: flex;
  align-items: center;
  max-width: 300px;
  background: #fff;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  padding-left: 0.5rem;
  overflow: hidden;
  font-family: sans-serif;
}
.input-box .prefix {
  font-weight: 300;
  font-size: 14px;
  color: #999;
}

.input-box input {
  flex-grow: 1;
  font-size: 14px;
  background: #fff;
  border: none;
  outline: none!important;
  padding: 0.5rem;
  box-shadow: none!important;
}

.input-box:focus-within {
  border-color: #777;
}