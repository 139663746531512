.SkeletonContainer {
  background-color: white;
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 0 10px #00276617;
  padding: 20px;
}

.Skeleton {
  margin-top: 40px;
}

.TopicListContainer {
  width: 100%;
}
