body {
    overflow-x: hidden;
}

#sidebar-wrapper {
    min-height: 100vh;
    margin-left: -15rem;
    -webkit-transition: margin .25s ease-out;
    -moz-transition: margin .25s ease-out;
    -o-transition: margin .25s ease-out;
    transition: margin .25s ease-out;
}

#sidebar-wrapper .sidebar-heading {
    padding: 0.875rem 1.25rem;
    font-size: 1.2rem;
}

#sidebar-wrapper .list-group {
    width: 15rem;
}

#page-content-wrapper {
    min-width: 100vw;
}

#wrapper.toggled #sidebar-wrapper {
    margin-left: 0;
}
.ck-editor__editable_inline {
    height: 400px;
    width: 100%;;
}
.publish-box{
    margin-left: 24px;
    margin-top: 10px;
}
@media (min-width: 768px) {
    #sidebar-wrapper {
        margin-left: 0;
    }

    #page-content-wrapper {
        min-width: 0;
        width: 100%;
    }

    #wrapper.toggled #sidebar-wrapper {
        margin-left: -15rem;
    }
}

#as-react-datatable-container select {
    width: 70px !important;
}

table.table thead th {
    color: #2196F3;
}
header a,
header a:hover {
    color: #fff;
}
.requiredsymbol {
    color:red;
}
.col-sm-3 {
    margin-bottom: 15px;
}
.card {
    min-height: 180px;
}
.card-title {
    color: #fff;
}
.card-text {
    min-height: 48px;
}